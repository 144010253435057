<template>
  <base-detail @closePage="closePage">
    <detail-list :formData="formData" :config="config">
      <!-- 开始记录时间 -->
      <template v-slot:recordStartTime="{ data }">
        {{ $util.format(data.recordStartTime, 'yyyy-MM-dd hh:mm:ss:S') }}
      </template>
      <!-- 记录结束时间 -->
      <template v-slot:recordEndTime="{ data }">
        {{ $util.format(data.recordEndTime, 'yyyy-MM-dd hh:mm:ss:S') }}
      </template>
      <!-- 会话来源 -->
      <template v-slot:contextFrom="{ data }">
        {{ contextFromObj[data.contextFrom] }}
      </template>
      <!-- 是否发生异常 -->
      <template v-slot:hasException="{ data }">
        {{ yesOrNoObj[data.hasException] }}
      </template>
      <!-- 是否有慢sql -->
      <template v-slot:slowQuery="{ data }">
        {{ yesOrNoObj[data.slowQuery] }}
      </template>
      <!-- 发生异常信息 -->
      <template v-slot:exception="{ data }">
        <el-input
          type="textarea"
          autosize
          :value="data.exception"
          :disabled="false"
        />
      </template>
      <!-- 请求参数json -->
      <template v-slot:requestParameters="{ data }">
        <json-view :json="JSON.parse(data.requestParameters)" />
      </template>
      <!-- 响应参数json -->
      <template v-slot:responseParameters="{ data }">
        <json-view :json="JSON.parse(data.responseParameters)" />
      </template>
      <!-- 请求设计的sql执行信息 -->
      <template v-slot:jdbcEventDocuments="{ data }">
        <json-view :json="data.jdbcEventDocuments" />
      </template>
    </detail-list>
  </base-detail>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      contextFromObj: this.$util.listToObj(this.$dict.contextFrom()),
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      formData: null,
      config: [
        // lang: 创建人
        {
          label: this.$t('logManage.createBy'),
          prop: 'createBy',
          span: 12,
        },
        // lang: 开始记录时间
        {
          label: this.$t('logManage.recordStartTime'),
          prop: 'recordStartTime',
          span: 12,
        },
        // lang: 记录结束时间
        {
          label: this.$t('logManage.recordEndTime'),
          prop: 'recordEndTime',
          span: 12,
        },
        // lang: 所用时长(ms)
        {
          label: this.$t('logManage.recordSpentTime'),
          prop: 'recordSpentTime',
          span: 12,
        },
        // lang: 会话来源
        {
          label: this.$t('logManage.contextFrom'),
          prop: 'contextFrom',
          span: 12,
        },
        // lang: 会话编号
        {
          label: this.$t('logManage.seq'),
          prop: 'seq',
          span: 12,
        },
        // lang: 会话token
        {
          label: this.$t('logManage.subjectToken'),
          prop: 'subjectToken',
          span: 12,
        },
        // lang: 接口模块
        {
          label: this.$t('logManage.apiModule'),
          prop: 'apiModule',
          span: 12,
        },
        // lang: 接口模块说明
        {
          label: this.$t('logManage.apiModuleDescription'),
          prop: 'apiModuleDescription',
          span: 12,
        },
        // lang: 接口
        {
          label: this.$t('logManage.api'),
          prop: 'api',
          span: 12,
        },
        // lang: 接口说明
        {
          label: this.$t('logManage.apiDescription'),
          prop: 'apiDescription',
          span: 12,
        },
        // lang: 是否发生异常
        {
          label: this.$t('logManage.hasException'),
          prop: 'hasException',
          span: 12,
        },
        // lang: 是否有慢sql
        {
          label: this.$t('logManage.slowQuery'),
          prop: 'slowQuery',
          span: 12,
        },
        // lang: 异常名称完整路径
        {
          label: this.$t('logManage.exceptionName'),
          prop: 'exceptionName',
          span: 12,
        },
        // lang: 异常名称
        {
          label: this.$t('logManage.exceptionSimpleName'),
          prop: 'exceptionSimpleName',
          span: 12,
        },
        // lang: 发生异常信息
        {
          label: this.$t('logManage.exception'),
          prop: 'exception',
        },
        // lang: 请求参数json
        {
          label: this.$t('logManage.requestParameters'),
          prop: 'requestParameters',
        },
        // lang: 响应参数json
        {
          label: this.$t('logManage.responseParameters'),
          prop: 'responseParameters',
        },
        // lang: 请求设计的sql执行信息
        {
          label: this.$t('logManage.jdbcEventDocuments'),
          prop: 'jdbcEventDocuments',
        },
      ],
    }
  },
  mounted() {
    this.findDetail()
  },
  methods: {
    // 关闭页面按钮点击回调
    closePage() {
      this.$emit('closePage')
    },
    // 查询会话详情
    findDetail() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.contextLogGetDetail,
        data: {
          seq: this.tableRow.seq,
        },
      }).then((data) => {
        this.formData = data
      })
    },
  },
}
</script>