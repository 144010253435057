<template>
  <div>
    <base-page
      :title="$t('logManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
    >
      <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.contextLogFind"
        :columns="columns"
        :defaultParams="defaultParams"
        rowName="seq"
        v-model="tableRow"
      >
        <!-- 开始记录时间 -->
        <template v-slot:recordStartTime="{ row }">
          {{ $util.format(row.recordStartTime, 'yyyy-MM-dd hh:mm:ss:S') }}
        </template>
        <!-- 记录结束时间 -->
        <template v-slot:recordEndTime="{ row }">
          {{ $util.format(row.recordEndTime, 'yyyy-MM-dd hh:mm:ss:S') }}
        </template>
        <!-- 会话来源 -->
        <template v-slot:contextFrom="{ row }">
          {{ contextFromObj[row.contextFrom] }}
        </template>
        <!-- 是否发生异常 -->
        <template v-slot:hasException="{ row }">
          {{ yesOrNoObj[row.hasException] }}
        </template>
        <!-- 是否有慢sql -->
        <template v-slot:slowQuery="{ row }">
          {{ yesOrNoObj[row.slowQuery] }}
        </template>
      </table-list>
    </base-page>
    <page-detail
      v-if="showPageDetail"
      :tableRow="tableRow"
      @closePage="closePageDetail"
    />
  </div>
</template>

<script>
import PageDetail from './pageDetail'

export default {
  components: {
    PageDetail,
  },
  data() {
    const nowTime = new Date().getTime()
    const prevTime = nowTime - 365 * 24 * 60 * 60 * 1000
    const startCreateTime = this.$util.format(prevTime, 'yyyy-MM-dd')
    const endCreateTime = this.$util.format(nowTime, 'yyyy-MM-dd')
    return {
      defaultParams: {
        startCreateTime: new Date(startCreateTime + ' 00:00:00').getTime(),
        endCreateTime: new Date(endCreateTime + ' 23:59:59').getTime(),
      },
      // 搜索
      formData: {
        createTime: [startCreateTime, endCreateTime],
      },
      config: [
        // lang:开始时间和结束时间
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
        // lang:会话来源
        {
          tag: 'el-select',
          label: this.$t('logManage.contextFrom'),
          span: 8,
          prop: 'contextFrom',
          tagProps: {
            options: this.$dict.contextFrom(),
          },
        },
        // lang:会话编号
        {
          label: this.$t('logManage.seq'),
          prop: 'seq',
          span: 8,
        },
        // lang:会话TOKEN
        {
          label: this.$t('logManage.subjectToken'),
          prop: 'subjectToken',
          span: 8,
        },
        // lang:是否发生异常
        {
          tag: 'el-select',
          label: this.$t('logManage.hasException'),
          prop: 'hasException',
          span: 8,
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
        },
        // lang:是否有慢sql
        {
          tag: 'el-select',
          label: this.$t('logManage.slowQuery'),
          prop: 'slowQuery',
          span: 8,
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
        },
        // lang:会话耗时大于等于
        {
          label: this.$t('logManage.recordSpentTimeGte'),
          prop: 'recordSpentTimeGte',
          span: 8,
        },
        // lang:接口
        {
          tag: 'el-cascader',
          label: this.$t('logManage.api'),
          prop: 'api',
          span: 8,
          tagProps: {
            options: [],
            props: {
              label: 'apiDescription',
              value: 'api',
            },
          },
        },
        // lang:账号
        {
          label: this.$t('logManage.loginName'),
          prop: 'loginName',
          span: 8,
        },
        // lang:创建人
        {
          label: this.$t('logManage.createBy'),
          prop: 'createBy',
          span: 8,
        },
      ],
      // 表格页
      tableRow: null,
      contextFromObj: this.$util.listToObj(this.$dict.contextFrom()),
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      columns: [
        // lang:创建人
        {
          label: this.$t('logManage.createBy'),
          prop: 'createBy',
        },
        // lang:账号
        {
          label: this.$t('logManage.loginName'),
          prop: 'loginName',
        },
        // lang:开始记录时间
        {
          label: this.$t('logManage.recordStartTime'),
          prop: 'recordStartTime',
        },
        // lang:记录结束时间
        {
          label: this.$t('logManage.recordEndTime'),
          prop: 'recordEndTime',
        },
        // lang:所用时长(ms)
        {
          label: this.$t('logManage.recordSpentTime'),
          prop: 'recordSpentTime',
        },
        // lang:会话来源
        {
          label: this.$t('logManage.contextFrom'),
          prop: 'contextFrom',
        },
        // lang:会话编号
        {
          label: this.$t('logManage.seq'),
          prop: 'seq',
        },
        // lang:会话TOKEN
        {
          label: this.$t('logManage.subjectToken'),
          prop: 'subjectToken',
        },
        // lang:接口模块
        {
          label: this.$t('logManage.apiModuleDescription'),
          prop: 'apiModuleDescription',
        },
        // lang:接口
        {
          label: this.$t('logManage.apiDescription'),
          prop: 'apiDescription',
        },
        // lang:是否发生异常
        {
          label: this.$t('logManage.hasException'),
          prop: 'hasException',
        },
        // lang:是否有慢sql
        {
          label: this.$t('logManage.slowQuery'),
          prop: 'slowQuery',
        },
      ],
      // 详情页
      showPageDetail: false,
    }
  },
  mounted() {
    this.apiFindTree()
  },
  methods: {
    // 查询api
    apiFindTree() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.apiFindTree,
      }).then((data) => {
        this.config[7].tagProps.options = data[0].children
      })
    },
    // 关闭详情页回调
    closePageDetail() {
      this.showPageDetail = false
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.api) formData.api = formData.api.pop()
      this.defaultParams = {}
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'view':
          this.showPageDetail = true
          break
        default: // do something
      }
    },
  },
}
</script>